<template>
  <div id="rmbPayBatch">
    <header-nav :content="`${$t('pages_router_021')} / ${ $t('pages_router_055')}`" />
    <a-spin :spinning="spinLoading" :tip="$t('common_text_031')" style="position: fixed; top: 20%; left: 5%">
      <div class="common-card-body">
        <div class="batch-header" @click="$router.replace('/funds/rmb')">
          <left-outlined class="ic-back" />
          {{ $t('pages_router_012') }}
        </div>
        <div class="apply-area">
          <a-form
            ref="applyRef"
            class="ant-round-form batch-apply-form"
            layout="vertical"
            :model="applyForm"
            :rules="applyRules"
            @finish="applySubmit"
            @finishFailed="submitError"
          >
            <a-row>
              <a-col :span="10">
                <a-form-item name="payCur" :label="$t('pages_pay_071')">
                  <a-select
                    v-model:value="applyForm.payCur"
                    size="large"
                    show-search
                    option-filter-prop="label"
                    class="cur-select"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in payCurList"
                      :key="item.currency"
                      :value="item.currency"
                      :label="`${item.currency} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.currency)" class="select-img-country">
                      <span>{{ item.currency }} - {{ item.currencyName }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4" class="flex-align" style="justify-content: center">
                <img class="ic-single" src="~@/assets/icons/ic-single.png">
              </a-col>
              <a-col :span="10">
                <a-form-item name="receiveCur" :label="$t('pages_pay_072')">
                  <a-select
                    v-model:value="applyForm.receiveCur"
                    size="large"
                    disabled
                    show-search
                    option-filter-prop="label"
                    class="cur-select"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in receiveCurList"
                      :key="item.currencyCode"
                      :value="item.currencyCode"
                      :label="`${item.currencyCode} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
                      <span>{{ item.currencyCode }} - {{ item.currencyName }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <div v-show="applyForm.payCur !== 'CNY'" style="margin-bottom: 24px">
              <div class="exchange-rate flex-align">
                <a-spin v-if="loading" :tip="$t('pages_exchange_030')" />
                <div v-else-if="rate" class="rate-info flex-align">
                  <img src="~@/assets/icons/ic-great.png">
                  <div class="rate flex-align">
                    <a-tooltip :title="$t('pages_pay_046')">
                      <span>{{ $t('pages_exchange_028') }}</span>
                      <span style="margin-left: 5px">1 {{ arithmetic === 'DIV' ? applyForm.receiveCur : applyForm.payCur }} = {{ rate }} {{ arithmetic === 'DIV' ? applyForm.payCur : applyForm.receiveCur }}</span>
                    </a-tooltip>
                  </div>
                  <div class="update-date">
                    {{ $t('pages_exchange_029') }} {{ queryDate }}
                  </div>
                </div>
                <div v-else class="rate-error">{{ resMsg }}</div>
                <div v-if="!loading" class="refresh-process">
                  <count-down
                    :valid-time="validTime"
                    :trade-currency="`${applyForm.payCur}${applyForm.receiveCur}`"
                    @refresh="loadExchangeRate"
                  />
                </div>
              </div>
              <div v-if="rate" class="explain">{{ $t('pages_pay_046') }}</div>
            </div>
            <a-form-item name="tradeType">
              <template #label>
                <div class="flex-align" style="width: 100%">
                  <span>{{ $t('pages_pay_030') }}</span>
                  <div v-if="applyForm.tradeType === '121011'" class="amount-text">
                    <info-circle-filled class="ic-tip" />
                    <span>{{ $t('pages_pay_031') }}: CNY {{ parseMoney(availabelQuota) }}</span>
                  </div>
                </div>
              </template>
              <a-select
                v-model:value="applyForm.tradeType"
                :placeholder="$t('common_text_005')"
              >
                <a-select-option
                  v-for="item in tradeTypeList"
                  :key="item.code"
                  :value="item.code"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="applyForm.tradeType" name="settlementType" :label="$t('pages_pay_036')">
              <div class="settle-list">
                <div class="settle-card active" style="width: 328px">
                  <div class="title">{{ $t('pages_pay_043') }}</div>
                  <div class="content">
                    <div>{{ $t('pages_pay_039') }}</div>
                    <div style="margin-top: 5px">{{ $t('pages_pay_044') }}</div>
                    <div>{{ $t('pages_pay_045') }}</div>
                    <div v-if="normalStandard" style="margin-top: 8px">
                      <div>{{ $t('pages_pay_042') }}</div>
                      <div v-if="normalStandard.feeAmt && normalStandard.feeRate">{{ normalStandard.feeAmt + normalStandard.feeCurrency }}/{{ $t('pages_pay_295') }} + {{ $t('pages_pay_294') }}*{{ normalStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                      <div v-else-if="!normalStandard.feeAmt && normalStandard.feeRate">{{ $t('pages_pay_294') }}*{{ normalStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                      <div v-else>{{ normalStandard.feeAmt + normalStandard.feeCurrency }}/{{ $t('pages_pay_295') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-item>
            <a-form-item
              v-if="applyForm.tradeType && applyForm.settlementType"
              name="payPath"
              :label="$t('pages_pay_073')"
            >
              <upload-auto
                ref="uploadPRef"
                v-model:file-content="applyForm.payPath"
                accept=".xls,.xlsx"
                :is-down="true"
                :notes="$t('pages_pay_074')"
                :explain="$t('pages_pay_075')"
                :max-count="1"
                @down="downPayTemplate"
              />
            </a-form-item>
            <div v-if="fillMaterial" class="flex-align">
              <span>{{ $t('pages_pay_248') }}</span>
              <a-button
                style="margin-left: auto"
                type="primary"
                size="small"
                danger
                @click="$router.push({ path: '/funds/payee_manage', query: { key: 'inbound' }})"
              >{{ $t('pages_pay_249') }}</a-button>
            </div>
            <a-form-item
              v-if="applyForm.tradeType && (applyForm.tradeType !== '121011' || (applyForm.tradeType === '121011' && uploadAmount > availabelQuota))"
              name="materialPath"
              :label="$t('pages_pay_051')"
            >
              <upload-auto
                ref="uploadMRef"
                v-model:file-content="applyForm.materialPath"
                :is-down="matchType(applyForm.tradeType)"
                :accept="matchType(applyForm.tradeType) ? '.xls,.xlsx' : '.zip'"
                :notes="matchType(applyForm.tradeType) ? $t('pages_pay_054') : ''"
                :explain="matchType(applyForm.tradeType) ? $t('pages_pay_052') : $t('pages_pay_053')"
                :max-count="1"
                @down="downMaterialTemplate"
              />
            </a-form-item>
            <a-form-item v-if="getUsedCheck(pageLabs, 150102)" style="text-align: right">
              <a-button
                :loading="submitLoading"
                html-type="submit"
                type="primary"
                shape="round"
                style="min-width: 138px; margin-top: 24px"
              >{{ $t('common_text_002') }}</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import CountDown from '@/components/count-down'
import UploadAuto from '@/components/upload-auto'
import { ref, reactive, toRefs, computed, watch, createVNode } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import { _receive, _pay, _exchange, _account } from '@/api'
import i18n from '@/locale'
import { getCountryLogo, getAttrStatusList, parseMoney, matchType, getUsedCheck } from '@/util'
export default {
  name: 'RmbPayBatch',
  components: {
    'header-nav': HeaderNav,
    'count-down': CountDown,
    'upload-auto': UploadAuto
  },
  setup () {
    const applyRef = ref(null)
    const uploadMRef = ref(null)
    const uploadPRef = ref(null)

    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      payCurList: [],
      receiveCurList: [],
      pageLabs: [],
      rate: '',
      arithmetic: '',
      queryDate: '',
      resMsg: '',
      validTime: 12,
      availabelQuota: 0,
      uploadAmount: 0,
      loading: true,
      fillMaterial: false,
      submitLoading: false,
      spinLoading: false,
      normalStandard: null,
      applyForm: {
        accountNo: route.query.accountNo,
        tradeType: null,
        payCur: 'USD',
        receiveCur: 'CNY',
        payPath: '',
        materialPath: '',
        batchNo: '',
        settlementType: '00'
      },
      applyRules: {
        tradeType: [{ required: true, message: i18n.global.t('pages_pay_024') }],
        settlementType: [{ required: true }],
        payPath: [{ required: true, message: i18n.global.t('pages_pay_070') }],
        materialPath: [{ required: true, message: i18n.global.t('pages_pay_026') }],
        payCur: [{ required: true }],
        receiveCur: [{ required: true }]
      }
    })

    state.tradeTypeList = computed(() => {
      return getAttrStatusList('tradeCode')
    })

    watch(() => state.applyForm.tradeType,
      () => {
        state.applyForm.materialPath = ''
        state.applyForm.payPath = ''
        if (uploadMRef.value) {
          uploadMRef.value.fileData = []
          uploadMRef.value.fileList = []
        }
        if (uploadPRef.value) {
          uploadPRef.value.fileData = []
          uploadPRef.value.fileList = []
        }
        getReceiveStandard()
      }
    )

    watch(() => state.applyForm.payCur,
      () => {
        if (uploadPRef.value) {
          uploadPRef.value.fileData = []
          uploadPRef.value.fileList = []
        }
        loadExchangeRate()
      }
    )

    watch(() => state.applyForm.payPath,
      async (value) => {
        if (value) {
          state.spinLoading = true
          const { settlementType, tradeType, payCur, receiveCur, accountNo } = state.applyForm
          const res = await _pay.getBatchUploadAmount({
            accountNo,
            settlementType,
            bizType: tradeType,
            remitCurrency: payCur,
            arrivalCurrency: receiveCur,
            path: value,
            rate: state.rate,
            arithmetic: state.arithmetic
          })
          state.spinLoading = false
          if (res.data.succ) {
            const { data } = res.data
            if (data.failFileName) {
              Modal.confirm({
                title: i18n.global.t('pages_pay_061'),
                content: i18n.global.t('pages_pay_062'),
                icon: createVNode(CloseCircleOutlined, { style: { color: '#ff4d4f' }}),
                okText: i18n.global.t('common_text_019'),
                onOk: () => {
                  window.open(data.failFileName)
                }
              })
            } else if (data.checkResult) {
              message.error(data.checkResult)
              state.fillMaterial = true
            } else {
              state.applyForm.batchNo = data.batchNo
              state.uploadAmount = data.amount
              message.success(i18n.global.t('common_text_018'))
            }
          } else {
            state.applyForm.batchNo = ''
            message.error(res.data.resMsg)
          }
        }
      }
    )

    const downPayTemplate = async (callback = () => {}) => {
      const { payCur, receiveCur } = state.applyForm
      await _pay.downUploadTemplate({ remitCurrency: payCur, arrivalCurrency: receiveCur })
      callback()
    }

    const downMaterialTemplate = async (callback = () => {}) => {
      await _pay.downReducingMaterialTemplate({ bizType: state.applyForm.tradeType })
      callback()
    }

    const submitError = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const applySubmit = async () => {
      if (!state.rate) return message.error(i18n.global.t('pages_pay_060'))
      state.submitLoading = true
      const data = Object.assign({}, state.applyForm, {
        filePath: state.applyForm.materialPath,
        rate: state.rate,
        arithmetic: state.arithmetic
      })
      _pay.batchPayApply(data, true)
        .then(res => {
          state.submitLoading = false
          const { data } = res.data
          if (res.data.succ) {
            router.push({ path: '/funds/apply_success', query: { key: 'inbound' }})
          } else if (res.data.resCode === '020015') {
            Modal.error({
              title: i18n.global.t('pages_pay_061'),
              content: res.data.resMsg,
              okText: i18n.global.t('common_text_019'),
              onOk: () => {
                window.open(data.failFileName)
              }
            })
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const getReceiveStandard = async () => {
      state.normalStandard = null
      const res = await _pay.getFeeReceiveConfig({ tradeType: state.applyForm.tradeType })
      if (res.data.succ && res.data.data) {
        for (const item of res.data.data) {
          if (item.settlementType === '00') {
            state.normalStandard = item
            break
          }
        }
      }
    }

    const loadAvailabelQuota = async () => {
      const res = await _pay.getAvailabelAmount()
      if (res.data.succ) state.availabelQuota = res.data.data
    }

    const loadPayCurList = async () => {
      const res = await _pay.getCurAccount()
      if (res.data.succ) {
        state.payCurList = res.data.data
      }
    }

    const loadReceiveCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.receiveCurList = res.data.data
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1501 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadExchangeRate = async () => {
      state.loading = true
      state.rate = ''
      state.arithmetic = ''
      state.queryDate = ''
      const { payCur, receiveCur } = state.applyForm
      if (payCur === 'CNY') {
        state.rate = 1
        state.arithmetic = 'DIV'
        return
      }
      const res = await _exchange.getExchangeRate({ buyCurrency: receiveCur, sellCurrency: payCur })
      if (res.data.succ) {
        const { data } = res.data
        state.resMsg = ''
        state.rate = data.rate
        state.arithmetic = data.arithmetic
        state.queryDate = data.queryDate
        state.validTime = data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1)
      } else {
        state.resMsg = res.data.resMsg
        state.validTime = 12
        state.applyForm.payAmount = null
        state.applyForm.receiveAmount = null
      }
      state.loading = false
    }

    loadPayCurList()
    loadReceiveCurList()
    loadExchangeRate()
    loadAvailabelQuota()
    loadChildrenMenu()

    return {
      applyRef,
      uploadMRef,
      uploadPRef,
      parseMoney,
      matchType,
      getCountryLogo,
      getUsedCheck,
      submitError,
      applySubmit,
      loadExchangeRate,
      downPayTemplate,
      downMaterialTemplate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../styles/apply-batch.less';
</style>
